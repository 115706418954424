import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
    loading: false,
    error: null,
};

// Define the async thunk to delete an email by ID
export const deleteEmailById = createAsyncThunk('clientsEmails/deleteEmailById', async (id) => {
    const response = await fetch(`https://banknoteconsult.com/data_test/rania/DeleteMail.php?id=${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to delete email');
    }
});

// Create a slice for managing the state
const deleteEmailSlice = createSlice({
    name: 'deleteEmail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteEmailById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteEmailById.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteEmailById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export the reducer
export const deleteEmailReducer = deleteEmailSlice.reducer;
