import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    subCategoryProduct: [],
    loading: false,
    error: null,
};
export const fetchSubcategoryProduct = createAsyncThunk(
    'subCategoryProduct/fetchSubcategoryProduct',
    async ({ subcategory_id, item_code, language }) => {
        try {
            const url = `https://banknoteconsult.com/data_test/Products_Api/Get_Product_By_Code.php?subcategory_id=${subcategory_id}&item_code=${item_code}&lang=${language}`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },

            });

            if (!response.ok) {
                throw new Error('Failed to fetch subcategory products');
            }

            return response.json();
        } catch (error) {
            throw new Error('Failed to fetch products');
        }
    }
);


const subCategoryProductSlice = createSlice({
    name: 'subCategoryProduct',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubcategoryProduct.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSubcategoryProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.subCategoryProduct = action.payload;
            })
            .addCase(fetchSubcategoryProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const subcategoriesProductActions = subCategoryProductSlice.actions;
export const subcategoryProductReducer = subCategoryProductSlice.reducer;

