import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    category_id: '',
    category_name: '',
    loading: false,
    error: null,
}

export const fetchCategoryInfo = createAsyncThunk(
    'categoryInfo/fetchCategoryInfo',

    async ({ subcategory_id, language }) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/rania/getCategoryFromSubCategory.php', {
                method: 'POST',
                body: new URLSearchParams({
                    subcategory_id: subcategory_id,
                    lang: language
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch category info');
            }
            const data = await response.json();

            return data;
        } catch (error) {
            throw new Error('Failed to fetch category info');
        }
    }
);

const categoryInfoSlice = createSlice({
    name: 'categoryInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoryInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategoryInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.category_id = action.payload.category_id;
                state.category_name = action.payload.category_name;
            })
            .addCase(fetchCategoryInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const categoryInfoActions = categoryInfoSlice.actions;
export const categoryInfoReducer = categoryInfoSlice.reducer;
