import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    editing: false,
    loading: false,
    error: null,
};

// Define thunk to edit category with subcategories
export const editCategory = createAsyncThunk(
    'editcategorywithsubs/editCategory',
    async ({ id, category_name, category_image, category_icon, subcategoriess }) => {
        try {


            const subcategories = JSON.stringify(subcategoriess)
            const response = await fetch('https://banknoteconsult.com/data_test/rania/EditCategorywithSubs.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    id,
                    category_name,
                    category_image: category_image !== null ? category_image : '', // Handle null value
                    category_icon: category_icon !== null ? category_icon : '', // Handle null value
                    subcategories
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to edit category');
            }
            return response.json();
        } catch (error) {
            throw new Error('Failed to edit category');
        }
    }
);


// Create category slice
const categorySlice = createSlice({
    name: 'editcategorywithsubs',
    initialState,
    reducers: {
        setEditing: (state, action) => {
            state.editing = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(editCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(editCategory.fulfilled, (state) => {
                state.loading = false;
                state.editing = false;
            })
            .addCase(editCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export actions and reducer
export const { setEditing } = categorySlice.actions;
export const editcategorywithsubsReducer = categorySlice.reducer;
