import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchProductCount = createAsyncThunk(
    'productCount/fetchProductCount',
    async (userId) => {
        const response = await fetch(`https://banknoteconsult.com/data_test/List/ListCount.php?user_id=${userId}`);
        const data = await response.json();
        return data.count;
    }
);

export const productCountSlice = createSlice({
    name: 'listproductCount',
    initialState: {
        count: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductCount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProductCount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.count = action.payload;
            })
            .addCase(fetchProductCount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const selectProductCount = (state) => state.productCount.count;

export default productCountSlice.reducer;
