import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    products: [],
    formData: {
        companyName: '',
        name: '',
        email: '',
        phoneNumber: '',
        country: ''
    },
    loading: false,
    error: null,
};

export const submitForm = createAsyncThunk(
    'form/submitForm',
    async (requestData) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/TradingFrom/mails.php', {
                method: 'POST',

                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error('Failed to submit form');
            }

            return response.json();
        } catch (error) {
            throw new Error('Failed to submit form');
        }
    }
);

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setFormData: (state, action) => {
            state.formData = { ...state.formData, ...action.payload };
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        resetForm: (state) => {
            state.formData = initialState.formData;
            state.products = initialState.products;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitForm.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(submitForm.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                // You can update state as needed after successful form submission
            })
            .addCase(submitForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { setFormData, setProducts, resetForm } = formSlice.actions;
export default formSlice.reducer;
