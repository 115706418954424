import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore } from '@reduxjs/toolkit';
import { categoriesReducer } from './Features/Categories/User/CategorySlice';
import { listsReducer } from './Features/List/GetAllListSlice';
import { subcategoriesReducer } from './Features/SubCategories/SubInCatSlice';
import { registrationReducer } from './Features/Register/RegisterSlice';
import { loginReducer } from './Features/Login/LoginSlice';
import { updateUserReducer } from './Features/UpdateUserInfo/UpdateUserInfoSlice';
import { resetPasswordReducer } from './Features/UpdateUserInfo/ChangePassword';
import { uploadPDFReducer } from './Features/UpdateUserInfo/UploadPDFSlice';
import { removePDFReducer } from './Features/UpdateUserInfo/RemovePDF';
import { pdfReducer } from './Features/UpdateUserInfo/InitializePDF';
import { searchReducer } from './Features/Search/SearchSlice';
import { productInCategoryReducer } from './Features/ProductInCategory/ProductInCategorySlice'; // Import the product in category reducer
import { categoryReducer } from './Features/Categories/User/CategotyByIDSlice';
import { subcategoriesNameReducer } from './Features/SubCategories/getsubCategoryIdByName';
import { subcategoriesProductReducer } from './Features/products/User/ProductSlice';
import { listReducer } from './Features/List/CreateListSlice';
import { subcategoryProductReducer } from './Features/products/User/getProductbyCodeSlice';
import { subcategoryNameReducer } from './Features/SubCategories/getsubCategoryNameByID';
import { categoryInfoReducer } from './Features/Categories/User/CategorybysubSlice';
import { newArrivalReducer } from './Features/products/User/NewArrivalSlice';
import { productCountReducer } from './Features/ProductInCategory/ProductsCountAtCat';
import { productSubCountReducer } from './Features/SubCategories/ProductsCountInSub';
import { productsReducer } from './Features/products/User/GetAllProducts';
import { categoriesInfoReducer } from './Features/Categories/User/CategoriesInfoSlice';
import { categoriesAddReducer } from './Features/Categories/Admin/CategoriesAdd';
import { categoryDeleteReducer } from './Features/Categories/Admin/DeleteCatSlice';
import { categorywithsubsReducer } from './Features/Categories/User/GetCategoriesInfoSlice';
import { editcategorywithsubsReducer } from './Features/Categories/Admin/EditCategoriesInfoSlice';
import { clientsEmailsReducer } from './Features/Panel/ClientsEmails';
import { deleteEmailReducer } from './Features/Panel/ClientEmailDelete';
import { userReducer } from './Features/Panel/AdminSlice';
import { addItemToListReducer } from './Features/List/AddToExistingListSlice';
import { createProductsReducer } from './Features/products/Admin/CreateProductSlice';
import { ProductUpdate } from './Features/products/Admin/EditProductByCode';
import productpanalReducer from './Features/products/User/GetProductInfoSlice';
import ProductsInList from './Features/List/getProductsInList';
import deleteproductReducer from './Features/products/Admin/DeleteProductSlice';
import { updateProductReducer } from './Features/products/Admin/EditProductByCode';
import producteditReducer from './Features/products/Admin/EditProductSlice';
import formReducer from './Features/List/FormQouteSlice';
import { removelistReducer } from './Features/List/RemoveListSlice';
import { deleteProductReducer } from './Features/List/DeleteProductFromList'
import listproductCountReducer from './Features/List/ListCountSlice'

const store = configureStore({
    reducer: {
        categories: categoriesReducer,
        subcategories: subcategoriesReducer,
        lists: listsReducer,
        registration: registrationReducer,
        login: loginReducer,
        updateUser: updateUserReducer,
        resetPassword: resetPasswordReducer,
        uploadPDF: uploadPDFReducer,
        removePDF: removePDFReducer,
        pdf: pdfReducer,
        subcategoriesName: subcategoriesNameReducer,
        subCategoryProducts: subcategoriesProductReducer,
        list: listReducer,
        search: searchReducer,
        productInCategory: productInCategoryReducer,
        category: categoryReducer,
        subCategoryProduct: subcategoryProductReducer,
        subcategoryName: subcategoryNameReducer,
        categoryInfo: categoryInfoReducer,
        newArrival: newArrivalReducer,
        productCount: productCountReducer,
        productSubCount: productSubCountReducer,
        products: productsReducer,
        categoriesInfo: categoriesInfoReducer,
        categoriesAdd: categoriesAddReducer,
        categoryDelete: categoryDeleteReducer,
        categorywithsubs: categorywithsubsReducer,
        editcategorywithsubs: editcategorywithsubsReducer,
        clientsEmails: clientsEmailsReducer,
        deleteEmail: deleteEmailReducer,
        user: userReducer,
        AddProductToExistingList: addItemToListReducer,
        productscreate: createProductsReducer,
        updateProduct: updateProductReducer,
        deleteproduct: deleteproductReducer,
        ProductUpdate: updateProductReducer,
        productPanalInfo: productpanalReducer,
        productedit: producteditReducer,
        form: formReducer,
        ProductsInList: ProductsInList,
        removelist: removelistReducer,
        deleteProductFromList: deleteProductReducer,
        listproductCount: listproductCountReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
