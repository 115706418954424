import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit';

const initialState = {
    lists: [],
    loading: false,
    error: null,
}

export const fetchLists = createAsyncThunk(
    'lists/fetchLists',
    async (listData) => {
        const { user_id } = listData;
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/List/get_all_list.php', {
                method: 'POST',
                body: new URLSearchParams({
                    user_id,
                }),

                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                throw new Error('Faild to fetch lists');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to fetch lists');

        }
    }
)

const listsSlice = createSlice({
    name: 'lists',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLists.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLists.fulfilled, (state, action) => {
                state.loading = false;
                state.lists = action.payload;
            })
            .addCase(fetchLists.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    }
});

export const listsActions = listsSlice.actions;
export const listsReducer = listsSlice.reducer;