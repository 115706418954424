import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    category: null,
    loading: false,
    error: null,
};

export const fetchCategory = createAsyncThunk(
    'categorywithsubs/fetchCategory',
    async (categoryId) => {
        try {
            const response = await fetch(`https://banknoteconsult.com/data_test/rania/getCategoryWithSubs.php?category_id=${categoryId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch category');
            }
            return response.json();
        } catch (error) {
            throw new Error('Failed to fetch category');
        }
    }
);

const categorySlice = createSlice({
    name: 'categorywithsubs',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(fetchCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.category = action.payload;
            })
            .addCase(fetchCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const categorywithsubsActions = categorySlice.actions;
export const categorywithsubsReducer = categorySlice.reducer;
