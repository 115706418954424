import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//this Api not used in the website
// Define initial state
const initialState = {
    loading: false,
    error: null,
};

// Define async action creator to update product
export const updateProductByCode = createAsyncThunk(
    'product/updateProduct',
    async (payload) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/Products_Api/Edit_Product_By_Id.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Failed to update product');
            }

            return response.json();
        } catch (error) {
            throw new Error('Failed to update product');
        }
    }
);

// Create product slice
const updateProductSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateProductByCode.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProductByCode.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateProductByCode.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export async action creator and reducer
export const updateProductAction = updateProductSlice.actions;
export const updateProductReducer = updateProductSlice.reducer;
