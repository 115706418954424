import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    registrationStatus: null,
    loading: false,
    error: null,
    errorMessage: null,
};

// Create an async thunk to register a user
export const registerUser = createAsyncThunk(
    'user/registerUser',
    async (userData) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/RegLog/Register.php', {
                method: 'POST',
                body: new URLSearchParams(userData).toString(),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                // If response is not ok, throw an error with the message received from the server
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to register user');
            }
            const data = await response.json();
            return data;
        } catch (error) {

            throw error; // Throw the error to be handled in the rejected case
        }
    }
);

// Create a slice for user registration
const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.errorMessage = null; // Reset error message
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false;
                state.registrationStatus = action.payload.success ? 'success' : 'error';
                state.errorMessage = action.payload.message ? action.payload.message : null; // Set error message if present
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; // Set error to the message received from the rejected action
                state.registrationStatus = 'error';
                state.errorMessage = action.error.message || 'Failed to register user'; // Set errorMessage to the message received from the rejected action
            });
    },
});

// Export actions and reducer
export const registrationActions = registrationSlice.actions;
export const registrationReducer = registrationSlice.reducer;
