import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    searchResults: {
        primary: [],
        secondary: [],
    },
    loading: false,
    error: null,
};

// Create an async thunk to search for products based on the provided term
export const searchProducts = createAsyncThunk(
    'products/searchProducts',
    async ({ searchTerm, searchType }) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/Search/search.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `search_term=${searchTerm}`,
            });
            if (!response.ok) {
                throw new Error('Failed to search products');
            }
            const data = await response.json();
            return { data, searchType };
        } catch (error) {
            throw new Error('Failed to search products');
        }
    }
);

// Create a slice for search results
const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(searchProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchProducts.fulfilled, (state, action) => {
                state.loading = false;
                const { data, searchType } = action.payload;
                state.searchResults[searchType] = data;
            })
            .addCase(searchProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export actions and reducer
export const searchActions = searchSlice.actions;
export const searchReducer = searchSlice.reducer;
