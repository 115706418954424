import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    isAdmin: false,
    loading: false,
    error: null,
};

export const authenticateAdmin = createAsyncThunk(
    'user/authenticateAdmin',
    async () => {
        try {
            const userData = JSON.parse(localStorage.getItem('userData'));

            const sessionID = userData.sessionID;
            if (!sessionID) {
                throw new Error('Session ID not found in localStorage');
            }

            const response = await fetch('https://banknoteconsult.com/data_test/rania/AdminsAuth.php', {
                method: 'POST',
                body: new URLSearchParams({ sessionID }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to authenticate admin');
            }

            return response.json();
        } catch (error) {
            throw new Error('Failed to authenticate admin');
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(authenticateAdmin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(authenticateAdmin.fulfilled, (state, action) => {
                state.loading = false;
                state.isAdmin = action.payload.isAdmin;
            })
            .addCase(authenticateAdmin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
