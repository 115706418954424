import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';

// Define initial state
const initialState = {
    resetStatus: null,
    loading: false,
    error: null,
    errorMessage: null,
};

const hashPassword = (password) => {
    const hashedPassword = CryptoJS.SHA256(password).toString();
    return hashedPassword;
};

// Create an async thunk to reset the password
export const resetPassword = createAsyncThunk(
    'user/resetPassword',
    async (password) => {
        try {

            const { oldPassword, newPassword } = JSON.parse(password);
            const userData = JSON.parse(localStorage.getItem('userData'));
            const { Email, sessionID, userID } = userData;
            const hashedOldPassword = hashPassword(oldPassword);
            const hashedNewPassword = hashPassword(newPassword);
            const response = await fetch('https://banknoteconsult.com/data_test/RegLog/ResetPassword.php', {
                method: 'POST',
                body: new URLSearchParams({ id: userID, email: Email, oldPassword: hashedOldPassword, newPassword: hashedNewPassword, sessionID }).toString(),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                // If response is not ok, throw an error with the message received from the server
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to reset password');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            throw error; // Throw the error to be handled in the rejected case
        }
    }
);

// Create a slice for password reset
const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState,
    reducers: {
        reset(state) {
            // Reset state to initial state
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.errorMessage = null; // Reset error message
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.resetStatus = action.payload.success ? 'success' : 'error';
                state.errorMessage = action.payload.message ? action.payload.message : null; // Set error message if present
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; // Set error to the message received from the rejected action
                state.resetStatus = 'error';
                state.errorMessage = action.error.message || 'Failed to reset password'; // Set errorMessage to the message received from the rejected action
            });
    },
});

// Export actions and reducer
export const { reset } = resetPasswordSlice.actions;
export const resetPasswordReducer = resetPasswordSlice.reducer;
