import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    productsInCategory: [],
    loading: false,
    error: null,
};

// Create an async thunk to fetch products in a category from the API
export const fetchProductsInCategory = createAsyncThunk(
    'products/fetchProductsInCategory',
    async ({ categoryId, count }) => { // Default language to 'en'
        try {

            localStorage.getItem('lang')
            const response = await fetch('https://banknoteconsult.com/data_test/getAllProductAtCategory.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    category_id: categoryId,
                    count: count,
                    lang: localStorage.getItem('lang'),// Ensure lang is correctly included
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch products in category');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to fetch products in category');
        }
    }
);
// Create a slice for products in a category
const productInCategorySlice = createSlice({
    name: 'productInCategory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductsInCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProductsInCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.productsInCategory = action.payload;
            })
            .addCase(fetchProductsInCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export actions and reducer
export const productInCategoryActions = productInCategorySlice.actions;
export const productInCategoryReducer = productInCategorySlice.reducer;
