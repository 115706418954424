import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    list: '',
    loading: false,
    error: null,
};


export const createList = createAsyncThunk(
    'list/createList',
    async (listData, { dispatch, getState }) => {
        const { list_name, item_codes, user_id, subCategoryId } = listData;

        try {
            const response = await fetch('https://banknoteconsult.com/data_test/List/create_list.php', {
                method: 'POST',
                body: new URLSearchParams({
                    list_name,
                    item_codes,
                    user_id,
                    subCategoryId

                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to create a list');
            }
            return response.json();
        } catch (error) {
            throw new Error('Failed to create list');
        }
    }
);

const createListSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(createList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createList.fulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addCase(createList.rejected, (state, action) => {
                state.error = action.error.message;
            });

    },
})

export const listActions = createListSlice.actions;
export const listReducer = createListSlice.reducer;