import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    deletedCategory: null,
    loading: false,
    error: null,
};

export const deleteCategory = createAsyncThunk(
    'categoryDelete/deleteCategory',
    async (categoryId) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/rania/DeleteCategory.php', {
                method: 'POST',
                body: new URLSearchParams({
                    category_id: categoryId
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete category');
            }

            return response.json();
        } catch (error) {
            throw new Error('Failed to delete category');
        }
    }
);

const categoryDeleteSlice = createSlice({
    name: 'categoryDelete',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(deleteCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.deletedCategory = action.payload;
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const categoryDeleteActions = categoryDeleteSlice.actions;
export const categoryDeleteReducer = categoryDeleteSlice.reducer;
