import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


// Define initial state
const initialState = {
    userData: null,
    updateStatus: null,
    loading: false,
    error: null,
};


// Create an async thunk to update user info
export const updateUserInfo = createAsyncThunk(
    'user/updateUserInfo',
    async (newData) => {
        try {

            const { CompanyName, Country, UserName, PhoneNumber } = JSON.parse(newData);
            const userData = JSON.parse(localStorage.getItem('userData'));
            const { userID, Email, sessionID } = userData;
            const response = await fetch('https://banknoteconsult.com/data_test/RegLog/updateuserInfo.php', {
                method: 'POST',
                body: new URLSearchParams({
                    userID: userID,
                    email: Email,
                    sessionID: sessionID,
                    CompanyName: CompanyName,
                    Country: Country,
                    UserName: UserName,
                    PhoneNumber: PhoneNumber,

                }).toString(),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                // If response is not ok, throw an error with the message received from the server
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to update user info');
            }
            const data = await response.json();


            return data;
        } catch (error) {
            throw error; // Throw the error to be handled in the rejected case
        }
    }
);

// Create a slice for updating user info
const updateUserSlice = createSlice({
    name: 'updateUser',
    initialState,
    reducers: {
        reset(state) {
            // Reset state to initial state
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateUserInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUserInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.updateStatus = action.payload.success ? 'success' : 'error';
                state.error = action.payload.message ? action.payload.message : null;
            })
            .addCase(updateUserInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update user info';
            });
    },
});

// Export actions and reducer
export const { reset } = updateUserSlice.actions;
export const updateUserReducer = updateUserSlice.reducer;
