import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//this Api not used in website
const initialState = {
    subcategory_id: '',
    loading: false,
    error: null,
}

export const fetchSubcategoryId = createAsyncThunk(
    'subcategoryName/fetchSubcategoryId',

    async (subcategory_name) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/subCategory_getIdByName.php', {
                method: 'POST',
                body: new URLSearchParams({
                    subcategory_name: subcategory_name
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch subcategories');
            }
            const data = await response.json();


            return data;
        } catch (error) {
            throw new Error('Failed to fetch categoryid');
        }
    }
);

const subcategoriesNameSlice = createSlice({
    name: 'subcategory_id',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubcategoryId.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSubcategoryId.fulfilled, (state, action) => {
                state.loading = false;
                state.subcategory_id = action.payload;
            })
            .addCase(fetchSubcategoryId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});


export const subcategoriesNameActions = subcategoriesNameSlice.actions;
export const subcategoriesNameReducer = subcategoriesNameSlice.reducer;
