import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
    data: [],
    loading: false,
    error: null,
};

// Define the async thunk to fetch data from the API
export const fetchData = createAsyncThunk('clientsEmails/fetchData', async () => {
    const response = await fetch('https://banknoteconsult.com/data_test/rania/ClientMails.php');
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    return data;
});

// Create a slice for managing the state
const clientsEmailsSlice = createSlice({
    name: 'clientsEmails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export the async thunk and reducer
export const clientsEmailsActions = clientsEmailsSlice.actions;
export const clientsEmailsReducer = clientsEmailsSlice.reducer;