import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// First fetchProducts thunk
export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async ({ itemCodes, subcategoryIds }) => {
        const response = await fetch('https://banknoteconsult.com/data_test/List/getListProducts.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                item_codes: itemCodes.join(','), // Joining item codes with comma
                subcategory_ids: subcategoryIds.join(',') // Joining subcategory ids with comma
            })
        });
        const data = await response.json();
        return data;
    }
);

// Second fetchProducts2 thunk with a unique action type
export const fetchProducts2 = createAsyncThunk(
    'products/fetchProducts2',
    async ({ itemCodes, subcategoryIds, language }) => {
        const response = await fetch('https://banknoteconsult.com/data_test/List/getListProducts.php', {
            method: 'POST',

            body: JSON.stringify({
                item_codes: itemCodes,
                subcategory_ids: subcategoryIds,
                lang: language
            })
        });
        const data = await response.json();
        return data;
    }
);
// Products slice
const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchProducts2.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProducts2.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload;
            })
            .addCase(fetchProducts2.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default productsSlice.reducer;
