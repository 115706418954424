import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    category: null,
    loading: false,
    error: null,
};
export const createCategory = createAsyncThunk(
    'categoryAdd/createCategory',
    async ({ category_name, category_image, category_icon, subcategoriess }) => {
        try {
            const subcategories = JSON.stringify(subcategoriess)
            const response = await fetch('https://banknoteconsult.com/data_test/rania/CreateCategorywithSubs.php', {
                method: 'POST',
                body: new URLSearchParams({
                    category_name,
                    category_image,
                    category_icon,
                    subcategories
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to create category');
            }

            return response.json();
        } catch (error) {
            throw new Error('Failed to create category');
        }
    }
);

const categorySlice = createSlice({
    name: 'categoryAdd',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(createCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.category = action.payload;
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const categoryActions = categorySlice.actions;
export const categoriesAddReducer = categorySlice.reducer;
