import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: null,
};

export const addItemToList = createAsyncThunk(
    'list/addItemToList',
    async (itemData, { rejectWithValue }) => {
        const { list_name, item_code, user_id, subCategoryId, } = itemData;
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/List/Add_Product_to_Existing_List.php', {
                method: 'POST',
                body: new URLSearchParams({
                    list_name,
                    item_code,
                    subCategoryId,
                      user_id,
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || 'Failed to add item to list');
            }
            return response.json();
        } catch (error) {
            return rejectWithValue('Failed to add item to list');
        }
    }
);

const addItemToListSlice = createSlice({
    name: 'addItemToList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addItemToList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addItemToList.fulfilled, (state, action) => {
                state.loading = false;
                // Handle the response as needed
            })
            .addCase(addItemToList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || action.error.message;
            });
    },
});

export const addItemToListActions = addItemToListSlice.actions;
export const addItemToListReducer = addItemToListSlice.reducer;
