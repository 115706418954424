import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';

// Define initial state
const initialState = {
    userData: null,
    loginStatus: null,
    loading: false,
    error: null,
    errorMessage: null,
};

const hashPassword = (password) => {
    const hashedPassword = CryptoJS.SHA256(password).toString();
    return hashedPassword;
};

// Create an async thunk to login a user
export const loginUser = createAsyncThunk(
    'user/loginUser',
    async (loginData) => {
        try {
            const { loginemail, loginpassword } = loginData;
            const hashedPassword = hashPassword(loginpassword); // Hash the password
            const response = await fetch('https://banknoteconsult.com/data_test/RegLog/login.php', {
                method: 'POST',
                body: new URLSearchParams({ email: loginemail, password: hashedPassword }).toString(),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                // If response is not ok, throw an error with the message received from the server
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to login user');
            }
            const data = await response.json();
            // Save user data to local storage
            localStorage.setItem('userData', JSON.stringify(data));
            return data;
        } catch (error) {
            throw error; // Throw the error to be handled in the rejected case
        }
    }
);

export const fetchUserDataOnAppStart = createAsyncThunk(
    'login/fetchUserDataOnAppStart',
    async () => {
        try {
            // Fetch user data from local storage
            const userData = localStorage.getItem('userData');
            //DEBUG ONLY
            // console.log("Fetched user data:", userData);
            if (userData) {
                return JSON.parse(userData);
            } else {
                throw new Error('User data not found');
            }
        } catch (error) {
            throw error;
        }
    }
);


// Create a slice for user login
const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        reset(state) {
            // Reset state to initial state
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.errorMessage = null; // Reset error message
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.loginStatus = action.payload.success ? 'success' : 'error';
                state.errorMessage = action.payload.message ? action.payload.message : null; // Set error message if present
                if (action.payload.success) {
                    state.userData = { ...action.payload }; // Save user data in the state
                    delete state.userData.success; // Remove 'success' key from user data
                    delete state.userData.message; // Remove 'message' key from user data
                }
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; // Set error to the message received from the rejected action
                state.loginStatus = 'error';
                state.errorMessage = action.error.message || 'Failed to login user'; // Set errorMessage to the message received from the rejected action
            })
            .addCase(fetchUserDataOnAppStart.fulfilled, (state, action) => {
                state.userData = action.payload; // Set user data when fetched successfully
            })
            .addCase(fetchUserDataOnAppStart.rejected, (state, action) => {
                state.error = action.error.message; // Set error to the message received from the rejected action
            });
    },
});

// Export actions and reducer
export const { reset } = loginSlice.actions;
export const loginReducer = loginSlice.reducer;
