import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    deleteStatus: null,
    loading: false,
    error: null,
};

// Create an async thunk to remove PDF file
export const removePDF = createAsyncThunk(
    'pdf/removePDF',
    async ({ file_category }) => {
        try {
            const userData = JSON.parse(localStorage.getItem('userData'));
            const { Email } = userData;
            const response = await fetch('https://banknoteconsult.com/data_test/CompanyDocs/removePDF.php', {
                method: 'POST',
                body: new URLSearchParams({
                    user_email: Email,
                    file_category: file_category
                }).toString(),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (!response.ok) {
                // If response is not ok, throw an error with the message received from the server
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to remove PDF');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            throw error; // Throw the error to be handled in the rejected case
        }
    }
);

// Create a slice for removing PDF
const removePDFSlice = createSlice({
    name: 'removePDF',
    initialState,
    reducers: {
        reset(state) {
            // Reset state to initial state
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(removePDF.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(removePDF.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteStatus = action.payload.success ? 'success' : 'error';
                state.error = action.payload.message ? action.payload.message : null;
            })
            .addCase(removePDF.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to remove PDF';
            });
    },
});

// Export actions and reducer
export const { reset } = removePDFSlice.actions;
export const removePDFReducer = removePDFSlice.reducer;
