import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    industrialRecord: {
        uploadMessage: '',
        fileUrl: null,
        loading: false,
        error: null,
        progress: 0, // Track upload progress
        cancelController: null,
    },
    industrialLicense: {
        uploadMessage: '',
        fileUrl: null,
        loading: false,
        error: null,
        progress: 0, // Track upload progress
        cancelController: null,
    },
    commercialRecord: {
        uploadMessage: '',
        fileUrl: null,
        loading: false,
        error: null,
        progress: 0, // Track upload progress
        cancelController: null,
    }
};

// Thunk action to upload PDF
export const uploadPDF = createAsyncThunk(
    'upload/uploadPDF',
    async ({ file, fileType }, { dispatch, signal, rejectWithValue }) => {
        try {
            const userData = JSON.parse(localStorage.getItem('userData'));
            const { Email } = userData;
            const formData = new FormData();
            formData.append('user_email', Email);
            formData.append('file_category', fileType);
            formData.append('fileToUpload', file);

            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://banknoteconsult.com/data_test/CompanyDocs/uploader.php');

            const controller = new AbortController();

            if (signal) {
                signal.addEventListener('abort', () => {
                    xhr.abort();
                    dispatch(setCancelController({ fileType, controller }));
                    return;
                });
            }

            dispatch(setCancelController({ fileType, controller }));

            xhr.upload.onprogress = (event) => {
                const percentCompleted = Math.round((event.loaded * 100) / event.total);
                dispatch(updateUploadProgress({ fileType, progress: percentCompleted }));
            };

            dispatch(updateUploadProgress({ fileType, progress: 0 }));
            dispatch(updateUploadMessage({ fileType, message: '' }));
            dispatch(updateLoading({ fileType, loading: true }));

            const response = await new Promise((resolve, reject) => {
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.responseText));
                    } else {
                        reject(new Error('Failed to upload PDF'));
                    }
                };

                xhr.onerror = () => {
                    reject(new Error('Failed to upload PDF'));
                };

                xhr.send(formData);
            });

            dispatch(updateLoading({ fileType, loading: false }));
            dispatch(updateUploadMessage({ fileType, message: 'Upload successful' }));

            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Function to cancel upload
export const cancelUploadPDF = (fileType) => (dispatch, getState) => {
    const { cancelController } = getState().uploadPDF[fileType];

    if (cancelController) {
        cancelController.abort();
        dispatch(setCancelController({ fileType, controller: null }));
    }
};

// Function to set the cancellation controller
export const setCancelController = ({ fileType, controller }) => ({
    type: 'upload/setCancelController',
    payload: { fileType, controller },
});

// Slice for file upload
const uploadPDFSlice = createSlice({
    name: 'uploadPDF',
    initialState,
    reducers: {
        updateUploadProgress(state, action) {
            const { fileType, progress } = action.payload;
            state[fileType].progress = progress;
        },
        updateUploadMessage(state, action) {
            const { fileType, message } = action.payload;
            state[fileType].uploadMessage = message;
            state[fileType].loading = false;
        },
        updateLoading(state, action) {
            const { fileType, loading } = action.payload;
            state[fileType].loading = loading;
        },
        setCancelController(state, action) {
            const { fileType, controller } = action.payload;
            state[fileType].cancelController = controller;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadPDF.pending, (state, action) => {
                const { fileType } = action.meta.arg;
                state[fileType].loading = true;
                state[fileType].error = null;
            })
            .addCase(uploadPDF.fulfilled, (state, action) => {
                const { fileType } = action.meta.arg;
                state[fileType].loading = false;
                state[fileType].uploadMessage = action.payload.message;
                state[fileType].fileUrl = action.payload.fileUrl;
                state[fileType].cancelController = null;
            })
            .addCase(uploadPDF.rejected, (state, action) => {
                const { fileType } = action.meta.arg;
                state[fileType].loading = false;
                state[fileType].error = action.payload || 'Failed to upload PDF';
                state[fileType].cancelController = null;
            });
    },
});

// Export actions and reducer
export const { updateUploadProgress, updateUploadMessage, updateLoading } = uploadPDFSlice.actions;
export const uploadPDFReducer = uploadPDFSlice.reducer;
