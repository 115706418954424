import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    files: [],
    loading: false,
    error: null,
};

// Create an async thunk to fetch PDF files
export const fetchPDFFiles = createAsyncThunk(
    'pdf/fetchPDFFiles',
    async () => {
        try {
            const userData = JSON.parse(localStorage.getItem('userData'));
            const { Email } = userData;
            const response = await fetch('https://banknoteconsult.com/data_test/CompanyDocs/initialize.php', {
                method: 'POST',
                body: new URLSearchParams({
                    user_email: Email,
                }).toString(),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (!response.ok) {
                // If response is not ok, throw an error with the message received from the server
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to fetch PDF files');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            throw error; // Throw the error to be handled in the rejected case
        }
    }
);

// Create a slice for PDF files
const pdfSlice = createSlice({
    name: 'pdf',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPDFFiles.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPDFFiles.fulfilled, (state, action) => {
                state.loading = false;
                state.files = action.payload;
                state.error = null;
            })
            .addCase(fetchPDFFiles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch PDF files';
            });
    },
});

// Export actions and reducer
export const pdfReducer = pdfSlice.reducer;
export const { } = pdfSlice.actions; // No actions defined in this slice
