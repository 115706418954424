import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    productCount: null,
    loading: false,
    error: null,
};

// Define thunk to fetch product count
export const fetchProductCount = createAsyncThunk(
    'productCount/fetchProductCount',
    async (subcategory_id, { rejectWithValue }) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/numberOfProductsAtSubCategory.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' // Adjust content type to JSON
                },
                body: JSON.stringify({ subcategory_id }), // Send subcategory_id in the request payload
            });
            if (!response.ok) {
                throw new Error('Failed to fetch product count');
            }
            const data = await response.json();
            return data.total_product_count; // Assuming the API returns the total_product_count field
        } catch (error) {
            return rejectWithValue('Failed to fetch product count');
        }
    }
);

// Create a slice for product count
const productCountSlice = createSlice({
    name: 'productSubCount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductCount.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProductCount.fulfilled, (state, action) => {
                state.loading = false;
                state.productCount = action.payload;
            })
            .addCase(fetchProductCount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export actions and reducer
export const productSubCountActions = productCountSlice.actions;
export const productSubCountReducer = productCountSlice.reducer;
