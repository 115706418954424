import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: null,
};

export const deleteList = createAsyncThunk(
    'list/deleteList',
    async (listData) => {
        const { list_name, user_id } = listData;
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/List/delete_List.php', {
                method: 'POST',
                body: new URLSearchParams({
                    list_name,
                    user_id,
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to delete the list');
            }
            return response.json();
        } catch (error) {
            throw new Error('Failed to delete the list');
        }
    }
);

const deleteListSlice = createSlice({
    name: 'removelist',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(deleteList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteList.fulfilled, (state, action) => {
                state.loading = false;
                // You may not need to update state here if the list is no longer needed in the state
            })
            .addCase(deleteList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

    },
});

export const listActions = deleteListSlice.actions;
export const removelistReducer = deleteListSlice.reducer;
