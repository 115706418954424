import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    subcategory_name: '',
    loading: false,
    error: null,
}

export const fetchSubcategoryName = createAsyncThunk(
    'subcategoryName/fetchSubcategoryName',
    async ({ subcategory_id, language }) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/subCategory_getNameById.php', {
                method: 'POST',
                body: new URLSearchParams({
                    subcategory_id: subcategory_id,
                    lang: language
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch subcategory name');
            }
            const data = await response.json();
            return data.subcategory_name;
        } catch (error) {
            throw new Error('Failed to fetch subcategory name');
        }
    }
);

const subcategoryNameSlice = createSlice({
    name: 'subcategoryName',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubcategoryName.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSubcategoryName.fulfilled, (state, action) => {
                state.loading = false;
                state.subcategory_name = action.payload;
            })
            .addCase(fetchSubcategoryName.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const subcategoryNameActions = subcategoryNameSlice.actions;
export const subcategoryNameReducer = subcategoryNameSlice.reducer;
