// src/features/product/productSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    loading: false,
    error: null,
    successMessage: null,
};
// Define async action creator to update product
export const updateProduct = createAsyncThunk(
    'product/updateProduct',
    async ({ item_id, current_subcategory_id, new_subcategory_id, productCode, productName, productDescription, item_video_url, price, instock, maxQuantity, specifications, productColors, image1, image2, image3 }, { rejectWithValue }) => {
        try {

            const formData = new FormData();
            formData.append('item_id', item_id);
            formData.append('current_subcategory_id', current_subcategory_id);
            formData.append('new_subcategory_id', new_subcategory_id);
            formData.append('item_code', productCode);
            formData.append('item_title', JSON.stringify(productName));
            formData.append('item_description', JSON.stringify(productDescription));
            formData.append('item_video_url', item_video_url);
            formData.append('price', price);
            formData.append('instock', instock === "In Stock" ? "1" : "0");
            formData.append('quantity', maxQuantity);
            formData.append('product_specification', JSON.stringify(specifications));
            formData.append('product_colors', JSON.stringify(productColors));

            // Append image data
            formData.append('image1', image1);
            formData.append('image2', image2);
            formData.append('image3', image3);
            // const response = await fetch('https://data.banknoteconsult.com/rania/Edit_product_by_id.php', {

            const response = await fetch('https://banknoteconsult.com/data_test/rania/Edit_product_by_id.php', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const error = await response.json();
                return rejectWithValue(error.message || 'Failed to update product');
            }

            return response.json();
        } catch (error) {
            return rejectWithValue('Failed to update product');
        }
    }
);

// Create product slice
const updateProductSlice = createSlice({
    name: 'productedit',
    initialState,
    reducers: {
        clearMessages(state) {
            state.error = null;
            state.successMessage = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateProduct.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.successMessage = null;
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.successMessage = action.payload;
            })
            .addCase(updateProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

// Export actions and reducer
export const { clearMessages } = updateProductSlice.actions;
export default updateProductSlice.reducer;
