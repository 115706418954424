import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    subCategoryProducts: [],
    loading: false,
    error: null,
};

export const fetchSubcategoryProducts = createAsyncThunk(
    'subCategoryProducts/fetchSubcategoryProducts',
    async ({ subcategory_id, count, language }) => {
        try {

            const response = await fetch('https://banknoteconsult.com/data_test/getSubCategoryProductBySubcategoryId.php', {
                method: 'POST',
                body: new URLSearchParams({
                    subcategory_id,
                    count,
                    lang: language
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch subcategory products');
            }
            // console.log(await response.json());
            return response.json();
        } catch (error) {
            throw new Error('Faild to fetch products');
        }

    }
);

const subCategoryProductsSlice = createSlice({
    name: 'subCategoryProducts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubcategoryProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSubcategoryProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.subCategoryProducts = action.payload;
            })
            .addCase(fetchSubcategoryProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const subcategoriesProductActions = subCategoryProductsSlice.actions;
export const subcategoriesProductReducer = subCategoryProductsSlice.reducer;
