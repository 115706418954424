import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    subcategories: [],
    loading: false,
    error: null,
};

// Create an async thunk to fetch subcategories of a specific category ID from the API
export const fetchSubcategories = createAsyncThunk(
    'subcategories/fetchSubcategories',
    async ({ categoryId, language }) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/subCategory_get_all_of_category_id.php', {
                method: 'POST',
                body: new URLSearchParams({
                    category_id: categoryId,
                    lang: language
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch subcategories');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to fetch subcategories');
        }
    }
);

// Create a slice for subcategories
const subcategoriesSlice = createSlice({
    name: 'subcategories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubcategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSubcategories.fulfilled, (state, action) => {
                state.loading = false;
                state.subcategories = action.payload;
            })
            .addCase(fetchSubcategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export actions and reducer
export const subcategoriesActions = subcategoriesSlice.actions;
export const subcategoriesReducer = subcategoriesSlice.reducer;
