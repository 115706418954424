import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    category: null,
    loading: false,
    error: null,
};


export const fetchCategoryById = createAsyncThunk(
    'category/fetchCategoryById',
    async ({ id }) => { // Accept an object with id and lang parameters
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/rania/Category_get_by_id.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // Adjusted content type
                },
                body: new URLSearchParams({
                    id: id,
                    lang: localStorage.getItem('lang'), // Include lang in the request body
                }).toString(), // Convert the object to a URL-encoded string
            });

            if (!response.ok) {
                throw new Error('Failed to fetch category');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to fetch category');
        }
    }
);

// Create a slice for the single category
const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoryById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategoryById.fulfilled, (state, action) => {
                state.loading = false;
                state.category = action.payload;
            })
            .addCase(fetchCategoryById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export actions and reducer
export const categoryActions = categorySlice.actions;
export const categoryReducer = categorySlice.reducer;
