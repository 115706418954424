// src/redux/productSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
    product: null,
    status: 'idle',
    error: null
};

// Async thunk to fetch product information
export const fetchProduct = createAsyncThunk(
    'product/fetchProductPanal',
    async ({ subcategory_id, product_id }) => {
        const response = await fetch('https://banknoteconsult.com/data_test/rania/GetProductInfo.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                subcategory_id: subcategory_id,
                product_id: product_id
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    }
);

// Create the slice
const productSlice = createSlice({
    name: 'productPanalInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.product = action.payload;
            })
            .addCase(fetchProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

// Export the async thunk and the reducer
export default productSlice.reducer;
