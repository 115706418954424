import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk for creating a product
export const createProduct = createAsyncThunk(
    'products/createProduct',
    async (formData) => {
        const {
            productName,
            productCode,
            productDescription,
            productColors,
            productCategory,
            subCategory,
            maxQuantity,
            stockStatus,
            specifications,
            image1,
            image2,
            image3
        } = formData;

        try {
            const response = await fetch('https://banknoteconsult.com/data_test/Products_Api/Create_Product.php', {
                method: 'POST',
                body: new URLSearchParams({
                    subcategory_id: subCategory,
                    item_code: productCode,
                    item_title: productName,
                    item_description: productDescription,
                    image1: image1,
                    image2: image2,
                    image3: image3,
                    instock: stockStatus === "In Stock" ? "1" : "0",
                    quantity: maxQuantity,
                    product_specification: specifications,
                    product_colors: productColors
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to create product');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to create product');
        }
    }
);

// Initial state
const initialState = {
    products: [],
    loading: false,
    error: null,
};

// Create products slice
const productsSlice = createSlice({
    name: 'productscreate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createProduct.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createProduct.fulfilled, (state, action) => {
                state.loading = false;
                // Handle response data as needed, like updating state with newly created product
            })
            .addCase(createProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export actions and reducer
export const createProductsActions = productsSlice.actions;
export const createProductsReducer = productsSlice.reducer;
