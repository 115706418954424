import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const deleteProductFromList = createAsyncThunk(
    'list/deleteProduct',
    async ({ list_name, item_code, user_id }) => {

        try {
            const response = await fetch('https://banknoteconsult.com/data_test/List/delete_Product_from_List.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',

                },
                body: new URLSearchParams({ list_name: list_name, item_code: item_code, user_id: user_id }),

            });
            if (!response.ok) {
                throw new Error('Failed to delete product from list');
            }
            return response.json();
        } catch (error) {
            throw new Error('Failed to delete product from list');
        }
    }
);

const deleteProductSlice = createSlice({
    name: 'deleteProduct',
    initialState: {
        loading: false,
        error: null,
        successMessage: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteProductFromList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.successMessage = null;
            })
            .addCase(deleteProductFromList.fulfilled, (state, action) => {
                state.loading = false;
                state.successMessage = action.payload.message;
            })
            .addCase(deleteProductFromList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const deleteProductActions = deleteProductSlice.actions;
export const deleteProductReducer = deleteProductSlice.reducer;
