import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    newArrivalProducts: [],
    loading: false,
    error: null,
};

export const fetchNewArrivalProducts = createAsyncThunk(
    'newArrival/fetchNewArrivalProducts',
    async ({ language,businessType  }) => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/Products_Api/NewArrivalproducts.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    lang: language,
                    //   business_type: businessType 
                })
            }
            );
            if (!response.ok) {
                throw new Error('Failed to fetch new arrival products');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to fetch new arrival products');
        }
    }
);

const newArrivalSlice = createSlice({
    name: 'newArrival',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNewArrivalProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNewArrivalProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.newArrivalProducts = action.payload;
            })
            .addCase(fetchNewArrivalProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const newArrivalActions = newArrivalSlice.actions;
export const newArrivalReducer = newArrivalSlice.reducer;
